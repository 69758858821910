import LocalizedStrings from "localized-strings";
import formatters from "./formatters";

import { IProps as OgTagsProps } from "./OgTags";

const strings = new LocalizedStrings({
	ptBr: {
		formatters,
		home: "Bem vindo",
		ogTags: {
			type: "website",
			title: "Ciclik",
			name: "Ciclik - Reciclar é transformar",
			description: "Somos uma ferramenta online que implanta sustentabilidade nas empresas de forma simples, segura e barata, como um CLICK.",
			themeColor: "#09be54",
			keywords: [
				"loja sustentavel",
				"Progressive Web App",
				"reciclar",
			],
			twitter: {
				type: "summary",
			},
			image: {
				url: `https://${process.env.NEXT_PUBLIC_PAGE_URL || "ciclik.com.br"
				}/necessial_image_logo.png`,
				type: "image/png",
				width: 1024,
				height: 480,
			},
		} as Omit<Omit<OgTagsProps, "strings">, "url">,
		routeNames: {
			home: "Home",
			ciclik: "Ciclik",
			androidDownload: "Baixar para Android",
			iosDownload: "Baixar para IPhone",
			register: "Cadastrar",
			login: "Acessar minha conta",
			logout: "Sair",
			faq: "FAQ",
		},
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			invalidArgument: "Argumento inválido",
		},
		navbar: {
			close: "Fechar",
		},
		footer: {
			aboutUs: [
				"Fale conosco",
				"Telefone (71) 99653-4134",
				"E-mail contato@ciclik.com.br",
				"CNPJ: 33.205.590/0001-48",
				"Endereço: AVENIDA TANCREDO NEVES, 939",
				"ED. ESPLANADA TOWER SALA 907 - CAMINHO DAS ARVORES",
			],
			category: {
				blog: "Blog da Ciclik",
				allAboutESG: "Tudo sobre ESG",
				educationalMaterials: "Materiais Educativos Gratuitos",
				solidWastePolicy: "Política de Resíduos Sólidos",
				polluterPrinciple: "Princípio do poluidor pagador",
			},
		},
		fields: {
			name: {
				cpf: "Nome",
				cnpj: "Nome Fantasia",
			},
			cpf: "CPF",
			cnpj: "CNPJ",
			password: "Senha",
			confirmPassword: "Confirmar Senha",
			email: "E-mail",
			termsAndConditions: [
				"Concordo com os",
				"termos e condições",
				"de uso do aplicativo/plataforma Ciclik",
			],
		},
		components: {
			navbar: {
				close: "Fechar",
			},
			emptyCard: {
				back: "Voltar",
				emptyTitle: "Desculpe, não encontramos o que você precisava!",
			},
			imageUploader: {
				selectImageProfile: "Clique para selecionar imagem de perfil",
				selectImage: "Selecione uma imagem",
				improveVisualization: "Isso irá melhorar a visualização do produto na loja.",
			},
			modal: {
				title: "Escolha uma data",
			},
			infoSetcion: {
				title: "Programa de consumo sustentável",
				description: [
					"Nós transformamos seu estabelecimento em uma opção de consumo sustentável com apenas um “Click”. ",
					"De forma 100% on-line, implantamos sustentabilidade ao seu negócio e transformamos todos os impactos positivos em marketing verde para sua marca. ",
					"Seja nosso assinante e comunique-se agora com consumidores conscientes, preencha o cadastro gratuitamente e saiba mais sobre como nosso programa de consumo sustentável pode ser a chave para você aumentar e fidelizar sua base de clientes.",
				],
			},
			checkoutModal: {
				title: "Pagamento",
				paymentMethod: "Modal de Pagamento",
				paymentDescription: "Pagamento referenta à participação na ação entre amigos título da rifa com os bilhetes:",
				continue: "Continuar",
				success: "transação criada",
				onUserIsNotLoggedIn: "É necessário fazer login ou cadastrar-se",
			},
			transformRecycleSection: {
				title: {
					1: "Reciclar é ",
					2: "transformar.",
				},
				content: {
					1: "Ligamos sua empresa a uma demanda crescente de consumidores ávidos por negócios sustentáveis, mesmo que você ainda não tenha nenhuma iniciativa sustentável. Nosso propósito é gerar um ",
					2: "CICLO sustentável",
					3: " onde todos os participantes sairão ganhando! ",
					4: "Nossa tecnologia promove a sustentabilidade, implementando a reciclagem na sua empresa em um CLICK e ao mesmo tempo em que oferece seus produtos como opção de consumo para os seus clientes, que cada vez mais, tornam-se consciente e exigentes com a sustentabilidade.",
				},

			},
			preRegisterSection: {
				doRegister: "Fazer Pré-Cadastro Gratuito",
			},
			aboutUsSection: {
				content: {
					1: "Somos uma ferramenta online que implanta ",
					2: "sustentabilidade ",
					3: "nas empresas de forma ",
					4: "simples, segura e barata, ",
					5: "com um ",
					6: "CLIK",
				},
			},
			sustainableCycleSection: {
				title: {
					1: "Jornada do ",
					2: "ciclo sustentável",
				},
				card1: "Gestão tecnológica de resíduos na palma da sua mão.",
				card2: "Serviço de Localização",
				card3: "Mais Clientes!",
			},
			curiositySection: {
				title: "Você sabia que empresas que reciclam vendem cada vez mais?",
				curiosityTitle: "Uma pesquisa realizada pelo Union + Webster, aponta que",
				curiosityDescription: [
					"87% dos consumidores brasileiros preferem comprar de empresas sustentáveis.",
					"Além disso, minimizar os impactos no meio ambiente tem servido para criar uma boa imagem e atrair capital de investidores sustentáveis.",
					"É a nova forma de fazer negócios de maneira responsável e humanitária.",
				],
			},
			stepByStepSection: {
				firstCard: {
					title: "Empresas responsáveis reciclam com a Ciclik",
					text: "Empresas aderem à nossa solução de baixo custo para coleta seletiva de resíduos recicláveis",
					number: "1",
				},
				secondCard: {
					title: "Venda mais sendo sustentável",
					text: "Divulgamos os impactos positivos da sua marca a uma base exclusiva de consumidores conscientes",
					number: "2",
				},
				thirdCard: {
					title: "Fidelização de clientes",
					text: "Comunique-se diretamente com nossa base de consumidores conscientes, em nosso aplicativo exclusivo",
					number: "3",
				},
			},
			accessDashSection: {
				text: "Acessar Dashboard",
			},
			imperativeSection: {
				grid: [
					{
						icon: "signIn.svg",
						text: ["Cadastre seu negócio", "em nossa plataforma"],
					},
					{
						icon: "schedule.svg",
						text: ["Agende Coletas", "seletivas online"],
					},
					{
						icon: "spreadOut.svg",
						text: ["Divulgue a sua marca", "em nosso APP"],
					},
					{
						icon: "conquerAndLoyalize.svg",
						text: ["Conquiste e fidelize", "seus clientes"],
					},
				],
			},
			knowMoreSection: {
				title: "Somos a Ciclik, um ciclo de consumo sustentável",
				description: [
					"Ligamos sua empresa a consumidores conscientes,",
					"mesmo que você ainda não tenha nenhuma iniciativa sustentável",
				],
				knowMore: "Saiba +",
			},
			formSection: {
				title: "Faça o seu cadastro gratuitamente",
				register: "Cadastrar",
				confirmPassword: "Senhas não coincidem",
				success: "Conta cadastrada!",
				pf: "Pessoa Física",
				pj: "Pessoa Jurídica",
			},
			signCard: {
				title: "Sua empresa sustentável em um \"click\"",
				description: [
					"Simples",
					"Fácil",
					"Sustentável",
				],
				signNow: "Assine já",
			},
			introSection: {
				title: [
					"Reciclar é",
					"transformar.",
				],
			},
		},
		pages: {
			privacyPolicies: {
				title: "Políticas de privacidade de dados do Ciclik",
				sections: {
					step1: "SEÇÃO 1 - O QUE FAZEMOS COM AS SUAS INFORMAÇÕES?",
					step2: "SEÇÃO 2 - COMO NÓS ADQUIRIMOS CONSENTIMENTO?",
					step3: "SEÇÃO 3 - DIVULGAÇÃO",
					step4: "SEÇÃO 4 - ARMAZENAMENTO",
					step5: "SEÇÃO 5 - SERVIÇOS DE TERCEIROS",
					step6: "SEÇÃO 6 - SEGURANÇA",
					step7: "SEÇÃO 7 - IDADE DE CONSENTIMENTO",
					step8: "SEÇÃO 8 - ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE",
				},
				description: {
					step1: `Quando você se cadastra em nosso app ou efetua compra e venda em nossas lojas virtuais, coletamos as informações pessoais que você nos fornece, tais como seu nome, endereço e e-mail. 
Quando você navega pela nossa plataforma, recebemos também automaticamente o protocolo de internet do seu computador (endereço de IP) a fim de obter informações que nos ajudam a saber mais sobre seu navegador e sistema operacional. 
Marketing por e-mail (quando aplicável): Com sua permissão, podemos lhe enviar e-mails sobre nossa loja, novos produtos e outras atualizações.`,
					step2: `Quando você nos fornece as suas informações pessoais para completar uma transação, verificar seu cartão de crédito, 
fazer uma adição de mercadorias ou negociar transações em nossa plataforma, você está concordando com a nossa coleta e uso de informações pessoais apenas para esses fins específicos. 
Se pedirmos suas informações pessoais por uma razão secundária, como marketing, vamos pedir seu consentimento, ou te dar a oportunidade de dizer não. 
Como posso retirar o meu consentimento?
Caso depois de fornecer seus dados você mude de ideia, você pode retirar o seu consentimento quando quiser e assim evitar que entremos em contato para obter ou divulgar informações. 
Entre em contato conosco através do e-mail contato@ciclik.com.br ou por correio: Rua da Gratidão, 760, Salvador, BA, 41650-195, Brasil`,
					step3: "Podemos divulgar suas informações pessoais se formos obrigados por lei a fazê-lo ou se você violar nossos Termos de serviço. ",
					step4: `Nosso app/plataforma é hospedada pela Startapp.one. Eles nos fornecem uma plataforma de e-commerce online que nos permite vender nossos produtos e serviços.
Seus dados estão armazenados através do armazenamento de dados do Startapp.one, de bancos de dados e do aplicativo geral do Startapp.one. 
Eles armazenam dados em um servidor seguro protegido por firewall. 
Pagamento: Se você escolher um gateway de pagamento direto para completar a sua compra, a Startapp.one armazena seus dados de cartão de crédito. Eles são criptografados através do Padrão de segurança de dados do setor de pagamento com cartão (PCI-DSS). 
Seus dados de transação de compra são armazenados apenas pelo período necessário para completar sua transação de compra. Depois de finalizar a compra, suas informações de transação de compra são apagadas.
Todos os gateways de pagamento direto aderem aos padrões definidos pela PCI-DSS, que são gerenciados pelo Conselho de normas de segurança PCI. 
Ele é um esforço conjunto de marcas como Visa, MasterCard, American Express e Discover. 
Os requisitos da PCI-DSS ajudam a garantir a utilização segura de informações de cartão de crédito pela nossa loja e seus provedores de serviço. Para mais detalhes, leia os Termos de serviço ou a Política de privacidade da Startapp.one. `,
					step5: `No geral, nossos fornecedores terceirizados irão coletar,
usar e divulgar suas informações apenas na medida do necessário para permitir que eles realizem os serviços que eles nos fornecem. Entretanto, certos prestadores de serviços terceirizados, tais como gateways de pagamento e outros processadores de transações de pagamento,
têm suas próprias políticas de privacidade em relação à informação que somos obrigados a fornecer para eles sobre transações relacionadas a compras. Para esses fornecedores,
recomendamos que você leia suas políticas de privacidade para que você possa entender de que maneira suas informações pessoais serão usadas por esses fornecedores.
Especificamente, lembre-se que certos fornecedores podem estar localizados ou possuir instalações que ficam em jurisdições diferentes da sua ou da nossa.
Por isso, se você quiser continuar com uma transação que envolva um prestador de serviços terceirizado, suas informações podem ficar sujeitas à legislação da(s) jurisdição(ões) onde o prestador de serviços ou suas instalações estiverem localizados.
Por exemplo, se você está no Canadá e sua transação é processada por um gateway de pagamento nos Estados Unidos,
suas informações pessoais usadas para completar a transação podem estar sujeitas a divulgação sob a legislação dos Estados Unidos, incluindo a Lei Patriótica. Uma vez que você sair do site da nossa loja ou for redirecionado para um aplicativo ou site de terceiros,
você não será mais regido por essa Política de privacidade ou pelos Termos de serviço do nosso site.
Links Quando você clicar em links na nossa plataforma, eles podem lhe direcionar para fora do nosso site. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as políticas de privacidade deles.`,
					step6: `Para proteger suas informações pessoais, tomamos precauções e seguimos as melhores práticas da indústria para nos certificar que elas não sejam perdidas, usurpadas, acessadas, divulgadas, alteradas ou destruídas.
Se você nos fornece as suas informações de cartão de crédito, elas serão criptografadas usando a tecnologia "secure socket layer" (SSL) e armazenadas com criptografia AES-256. 
Embora nenhum método de transmissão pela Internet ou armazenamento eletrônico seja 100% seguro, nós seguimos todos os requisitos da PCI-DSS e implementamos medidas adicionais aceitas pelos padrões da indústria. `,
					step7: "Ao usar esse site, você confirma que você é maior de idade em seu estado ou província de residência e que você nos deu seu consentimento para permitir que qualquer um dos seus dependentes menores de idade usem esse site. ",
					step8: `Reservamos o direito de modificar essa política de privacidade a qualquer momento. Portanto, por favor, leia-a com frequência. 
As alterações e esclarecimentos surtem efeito imediatamente após serem publicadas no site.
Caso façamos alterações na política de privacidade, iremos notificá-lo sobre a atualização. Assim, você saberá quais informações coletamos, como as usamos, e sob que circunstâncias, caso aplicável, 
as utilizaremos e/ou divulgaremos. Caso ocorra a fusão da nossa loja com outra empresa, suas informações podem ser transferidas para os novos proprietários para que possamos continuar vendendo produtos para você.
PERGUNTAS E INFORMAÇÕES DE CONTATO Se você gostaria de acessar, corrigir, alterar ou excluir quaisquer informações pessoais que temos sobre você,
registre uma queixa, ou simplesmente peça mais informações de contato a(o) nosso(a) Diretor(a) de privacidade através do contato@ciclik.com.br ou pelo correio: [Avenida Orlando Gomes, S/N, Salvador, BA, 41650-190, Brazil].
`,
				},
			},
			termsOfUse: {
				title: "Política Privacidade e Termos de Uso",
				sections: {
					step1: "A sua privacidade é importante para nós.",
					step2: "Política de Cookies Ciclik:",
					step3: "Compromisso do Usuário:",
					step4: "Mais informações:",
				},
				description: {
					step1: `É política da Ciclik respeitar a sua privacidade em relação a qualquer informação sua que possamos coletar no site Ciclik, e outros sites que possuímos e operamos.
					Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e como será usado.
					Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis ​​para evitar perdas e roubos, bem como acesso,
divulgação, cópia, uso ou modificação não autorizados. Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por lei.                    
O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas políticas de privacidade.                                        
Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos fornecer alguns dos serviços desejados. 
O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e informações pessoais. 
Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais, entre em contato conosco.`,
					step2: `O que são cookies?                    
Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam, como as usamos e por que às vezes precisamos armazenar esses cookies.
Também compartilharemos como você pode impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos da funcionalidade do site.
Como usamos os cookies?                    
Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles adicionam a este site.
É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não deles, caso sejam usados ​​para fornecer um serviço que você usa.                    
Desativar cookies:                    
Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do navegador para saber como fazer isso). 
Esteja ciente de que a desativação de cookies afetará a funcionalidade deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.
Cookies que definimos:                                                                        
Cookies relacionados à conta - Se você criar uma conta conosco, usaremos cookies para o gerenciamento do processo de inscrição e administração geral.
Esses cookies geralmente serão excluídos quando você sair do sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site ao sair.
Cookies relacionados ao login - Utilizamos cookies quando você está logado, para que possamos lembrar dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página.
Esses cookies são normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos e áreas restritas ao efetuar login.
Cookies relacionados a boletins por e-mail - Este site oferece serviços de assinatura de boletim informativo ou e-mail e os cookies podem ser usados ​​para lembrar se você já está registrado e se deve mostrar determinadas notificações válidas apenas para usuários inscritos / não inscritos.
Pedidos processando cookies relacionados - Este site oferece facilidades de comércio eletrônico ou pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que possamos processá-lo adequadamente.
Cookies relacionados a pesquisas - Periodicamente, oferecemos pesquisas e questionários para fornecer informações interessantes,
ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos após a alteração das páginas.
Cookies relacionados a formulários - Quando você envia dados por meio de um formulário como os encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para lembrar os detalhes do usuário para correspondência futura.
Cookies de preferências do site - Para proporcionar uma ótima experiência neste site, fornecemos a funcionalidade para definir suas preferências de como esse site é executado quando você o usa.
Para lembrar suas preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você interagir com uma página for afetada por suas preferências.
Cookies de Terceiros - Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha quais cookies de terceiros você pode encontrar através deste site.
Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis ​​da Web, para nos ajudar a entender como você usa o site e como podemos melhorar sua experiência.
Esses cookies podem rastrear itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo conteúdo atraente. Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.
As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar produzindo conteúdo atrativo. 
Esses cookies podem rastrear itens como o tempo que você passa no site ou as páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você. 
Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta. 
Quando ainda estamos testando novos recursos, esses cookies podem ser usados ​​para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.
À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão.
Isso é importante para você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analisar nossos custos de publicidade e produtos para garantir o melhor preço possível.
O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para você.
Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google AdSense.
Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para futuros desenvolvimentos. Os cookies de publicidade comportamental usados ​​por este site foram projetados para garantir que você forneça os anúncios mais relevantes sempre que possível,
rastreando anonimamente seus interesses e apresentando coisas semelhantes que possam ser do seu interesse. 
Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos creditá-los adequadamente e, quando aplicável, 
permitir que nossos parceiros afiliados ofereçam qualquer promoção que pode fornecê-lo para fazer uma compra.`,
					step3: `O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Ciclik oferece no site e com caráter enunciativo, mas não limitativo:                                                                
A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;                        
B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, jogos de hoje ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos humanos;                        
C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Ciclik, de seus fornecedores ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou software que sejam capazes de causar danos anteriormente mencionados.`,
					step4: `Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você usa em nosso site.                    
Esta política é efetiva a partir de novembro/2021. `,
				},
			},
			faq: {
				title: "Perguntas Frequentes",
				questions: [
					{
						title: "Pergunta texto aqui texto aqui 1?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
					{
						title: "Pergunta texto aqui texto aqui 2?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
					{
						title: "Pergunta texto aqui texto aqui 3?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
					{
						title: "Pergunta texto aqui texto aqui 4?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
					{
						title: "Pergunta texto aqui texto aqui 5?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
					{
						title: "Pergunta texto aqui texto aqui 6?",
						description: [
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo",
							"Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo Seu texto aqui Seu texto aquo...",
						],
					},
				],
			},
		},
		externalLinks: {
			facebook: "https://www.facebook.com/ciclik.aplicativo",
			instagram: "https://instagram.com/ciclik.app?utm_medium=copy_link",
			termsOfUse: "https://docs.google.com/document/d/1F1at9OY7ZVq93mxW10npoW8Pgbp_Dd6XFItgU7vJ0bc/edit?usp=sharing",
		},
	},
});

export default strings;
