/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from "react";
import type { AppProps } from "next/app";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../theme";
import { bootAPI } from "~/resources/API";
import strings from "~/resources/strings";

const MyApp = ({ Component, pageProps }: AppProps) => (
	  <ChakraProvider theme={theme}>
		<Component {...pageProps} />
	  </ChakraProvider>
);
export default MyApp;

bootAPI(strings);
