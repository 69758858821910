import moment from "moment";
import "moment/locale/pt-br";

const formatters = {
	currencyBrl: (
		cents: number,
		options?: { showSymbol?: boolean; withCents?: boolean },
	) => {
		const withCents =
			options === undefined ||
			options.withCents === undefined ||
			options.withCents;
		const showSymbol =
			options === undefined ||
			options.showSymbol === undefined ||
			options.showSymbol;
		const numeroTokens: string[] = `${
			withCents ? (cents / 100).toFixed(2) : Math.round(cents / 100)
		}`.split(".");
		numeroTokens[0] = `${showSymbol ? "R$ " : ""}${numeroTokens[0]
			.split(/(?=(?:...)*$)/)
			.join(".")}`;
		return numeroTokens.join(",");
	},
	verifyIfHasExpired: (date: Date) => moment().isAfter(moment(date), "minute"),
	date: (date: Date) => `${moment(date).format("L")}`,
	removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
	expiryDate: (date: Date) => `${moment(date).endOf("hour").fromNow()}`,
	detailedDate: (date: Date) => `${moment(date).format("LL")}`,
	convertStringToDate: (date: string) => moment(date).toDate(),
	getPercentage: (current: number, limit: number) => Math.floor(current / limit * 100),
};

export default formatters;
